import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import Footer from '../components/Footer'
import CookieConsent from "react-cookie-consent";

const Skip = styled.a`
  padding: 0 1rem;
  line-height: 60px;
  background: #2867cf;
  color: white;
  z-index: 101;
  position: fixed;
  top: -100%;
  &:hover {
    text-decoration: underline;
  }
  &:focus,
  &:active,
  &:hover {
    top: 0;
  }
`

const Layout = props => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }
  useEffect(() => window.addEventListener('keydown', handleFirstTab), [])

  return (
    <div className="siteRoot">
      <CookieConsent
          location="bottom"
          disableStyles={true}
          buttonText="accetta"
          declineButtonText="Decline"
          buttonClasses="btn-primary"
          contentClasses="cookie-text-wrapper"
          cookieName="gatsby-gdpr-google-analytics">
        Questo sito utilizza cookie tecnici e di profilazione, propri o di terze parti. Puoi acconsentire all’utilizzo di tali tecnologie chiudendo questa informativa.
      </CookieConsent>
      <div className="siteContent">
        <Skip href="#main" id="skip-navigation">
          Skip to content
        </Skip>
        <div id="main" className={props.pageClass} style={{borderLeftColor: `${props.lateralStripes}`, borderRightColor: `${props.lateralStripes}`}}>{props.children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
