import React from 'react'

class LBContactInfo extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <ul className="contact-info">
        <li className="contact-info-mail"><a href="mailto:hello@lucabelli.me">hello@lucabelli.me</a></li>
        <li className="contact-info-separator"></li>
        <li className="contact-info-seguimi">Seguimi su:</li>
        <li className="contact-info-social">
          <a href="https://www.instagram.com/lucathinkup/" target="_blank">Instagram</a> / <a href="https://www.linkedin.com/in/luca-belli/" target="_blank">Linkedin</a>
        </li>
      </ul>
    )
  }
}

export default LBContactInfo
