import React from 'react'
import LBContactInfo from '../components/LBContactInfo'

const Footer = () => (
<footer>
  <div className="footer-wrapper row">
    <div className="lbfooter_column-1 col-md">
      <div className="lbfooter_title">Contatti</div>
      <div className="lbfooter_follow-me">
        <LBContactInfo />
      </div>
    </div>
    <div className="lbfooter_column-2 col-md">
      <div className="lb_footer-last-row">© 2022 by luca belli - All rights reserved</div>
    </div>
  </div>
</footer>
)

export default Footer
